import CenterContainer from "components/CenterContainer";
import { observer } from "mobx-react";
import * as React from "react";
import { ConsentDocumentFormDataInterface, consentDocumentStore, ConsentDocumentType, ConsentDocumentTypeLabel } from "stores/ConsentDocumentStore";
import { navigationStore } from "stores/NavigationStore";
import ConsentDocumentForm from "../Form";

@observer
class CreateConsentDocumentPage extends React.Component<any> {
  onSubmit = async (data: ConsentDocumentFormDataInterface) => {
    await consentDocumentStore.create(data);
    navigationStore.go("/consent-documents/"+this.props.params.type);
  };

  render() {
    const title = "Opret dokument for \""+ConsentDocumentTypeLabel[this.props.params.type as ConsentDocumentType]+"\"";
    return (
      <CenterContainer title={title}>
        <ConsentDocumentForm type={this.props.params.type} onSubmit={this.onSubmit} />
      </CenterContainer>
    );
  }
}

export default CreateConsentDocumentPage;
