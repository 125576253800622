import CenterContainer from "components/CenterContainer";
import NotificationForm, {
  NotificationFormDataInterface,
} from "components/NotificationForm";
import { observer } from "mobx-react";
import * as React from "react";
import { Params } from "react-router";
import { navigationStore } from "stores/NavigationStore";
import { notificationStore } from "stores/NotificationStore";

interface Props {
  params: Params<string>;
}

interface State {
  data: Parse.Object;
}

@observer
class EditNotificationPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    notificationStore
      .get(this.props.params.id as string)
      .then((notification: any) => {
        this.setState({ data: notification });
      });
  }

  onDelete = async () => {
    if (
      window.confirm("Er du sikker på at du vil slette denne notifikation?")
    ) {
      await notificationStore.delete(this.props.params.id as string);
      navigationStore.go("/");
    }
  };

  onSubmit = async (data: NotificationFormDataInterface) => {
    await notificationStore.update(data);
    navigationStore.go("/");
  };

  onDuplicate = async (id: string) => {
    await notificationStore.duplicate(id);
    navigationStore.go("/notifications");
  };

  parseNotificationToEditData = (
    notification: Parse.Object
  ): NotificationFormDataInterface | undefined => {
    if (!notification) return undefined;

    return {
      id: notification.id,
      receiverType: notification.get("receiver_type"),
      storeIds: notification.get("store_ids"),
      segmentIds: notification.get("segment_ids"),
      phoneNumber: notification.get("phone_number"),
      questionId: notification.get("questionId"),
      notification_icon_type: notification.get("notification_icon_type"),
      message: notification.get("message"),
      scheduleTime: notification.get("schedule_time"),
      title: notification.get("title"),
      url: notification.get("url"),
      pushType: notification.get("no_push"),
      published: notification.get("published"),
      showPopup: notification.get("show_popup"),
    };
  };

  render() {
    if (!this.state?.data) return null;
    const notification = this.state.data;
    const canSave = !notification.get("is_sent");

    return (
      <CenterContainer
        title={canSave ? "Rediger Notifikation" : "Vis Notifikation"}
      >
        <NotificationForm
          onDelete={this.onDelete}
          onSubmit={this.onSubmit}
          onDuplicate={this.onDuplicate}
          editData={this.parseNotificationToEditData(notification)}
          hideSaveButton={!canSave}
        />
      </CenterContainer>
    );
  }
}

export default EditNotificationPage;
