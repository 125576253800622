import Header from "components/Header";
import StatusIndicator from "components/StatusIndicator";
import { observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import { Parse } from "helpers/Parse";
import { Button, Table } from "rsuite";
import { authStore } from "stores/AuthStore";
import { bannerStore } from "stores/BannerStore";
import { navigationStore } from "stores/NavigationStore";
import BannerFilter from "./Filters";

const { Column, HeaderCell, Cell } = Table;

@observer
class IndexBannerPage extends React.Component {
  BANNERS_DEFAULT = 50;
  BANNERS_MAX = 9999;

  state = {
    getAllClicked: false,
    filterValue: "all",
    sortColumn: "createdAt",
    sortType: "desc",
    isLoading: true,
    data: [],
  };

  componentDidMount() {
    if (!Parse.User.current()) {
      navigationStore.go("/login");
    }

    bannerStore
      .getAll(this.BANNERS_DEFAULT, this.resolveQueryOptionsState())
      .then(() => {
        this.setState({ data: bannerStore.banners, isLoading: false });
      });
  }

  onRowClicked = (rowData: any) => {
    navigationStore.go(`/banners/${rowData.id}`);
  };

  resolveQueryOptionsState() {
    const { sortColumn, sortType, filterValue } = this.state;
    return { sortColumn, sortType, filterValue };
  }

  getAllNotifications = () => {
    this.setState({ isLoading: true });
    bannerStore
      .getAll(this.BANNERS_MAX, this.resolveQueryOptionsState())
      .then(() => {
        this.setState({
          getAllClicked: true,
          data: bannerStore.banners,
          isLoading: false,
        });
      });
  };

  handleSortColumn = (sortColumn: any, sortType: any) => {
    this.setState({ sortColumn, sortType, isLoading: true });
    bannerStore
      .getAll(this.BANNERS_DEFAULT, {
        ...this.resolveQueryOptionsState(),
        ...{ sortColumn, sortType },
      })
      .then(() => {
        this.setState({ data: bannerStore.banners, isLoading: false });
      });
  };

  handleFilterChanged = (filterValue: string) => {
    this.setState({ filterValue: filterValue, isLoading: true });
    bannerStore
      .getAll(this.BANNERS_DEFAULT, {
        ...this.resolveQueryOptionsState(),
        filterValue,
      })
      .then(() => {
        this.setState({ data: bannerStore.banners, isLoading: false });
      });
  };

  render() {
    const { getAllClicked, sortColumn, sortType, data, isLoading } = this.state;

    return (
      <div>
        <Header>
          <Button
            color="red"
            onClick={() => navigationStore.go("/banners/create")}
          >
            Opret nyt banner
          </Button>

          <Button
            style={{ marginLeft: "20px" }}
            onClick={() => authStore.logout()}
          >
            Log ud
          </Button>
        </Header>

        <BannerFilter onChange={this.handleFilterChanged}></BannerFilter>

        <Table
          data={data}
          rowKey="id"
          rowHeight={90}
          sortColumn={sortColumn}
          sortType={sortType as any}
          onSortColumn={this.handleSortColumn}
          onRowClick={this.onRowClicked}
          loading={isLoading}
          renderEmpty={() =>
            !isLoading ? (
              <div className="rs-table-body-info">Ingen bannere</div>
            ) : null
          }
          renderLoading={() => (
            <div className="rs-table-loader-wrapper">
              <div className="rs-table-loader">
                <i className="rs-table-loader-icon" />
                <span className="rs-table-loader-text">Henter bannere...</span>
              </div>
            </div>
          )}
          autoHeight
        >
          <Column width={120}>
            <HeaderCell>Image</HeaderCell>
            <Cell>
              {(rowData: any) => {
                return (
                  <img
                    src={rowData.get("file")?.url()}
                    alt="thumbnail"
                    width="120"
                  />
                );
              }}
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Titel</HeaderCell>
            <Cell>
              {(rowData: any) => {
                return rowData.get("title");
              }}
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Knap tekst</HeaderCell>
            <Cell>
              {(rowData: any) => {
                return rowData.get("cta_text");
              }}
            </Cell>
          </Column>
          <Column sortable flexGrow={1}>
            <HeaderCell>Sortering</HeaderCell>
            <Cell dataKey="sort_order">
              {(rowData: any) => {
                return rowData.get("sort_order");
              }}
            </Cell>
          </Column>
          <Column width={100}>
            <HeaderCell>Modtagere</HeaderCell>
            <Cell dataKey="receiverType">
              {(rowData: any) => {
                const receiverType = rowData.get("receiver_type");
                switch (receiverType) {
                  case "all":
                    return "Alle";
                  case "segment-store":
                    return "Segment/butik";
                  case "specific-customer":
                    return "Specifikt medlem";
                  default:
                    return receiverType;
                }
              }}
            </Cell>
          </Column>
          <Column sortable width={100}>
            <HeaderCell>Publiceret</HeaderCell>
            <Cell dataKey="published">
              {(rowData: any) => {
                return rowData.get("published") ? "Ja" : "Nej";
              }}
            </Cell>
          </Column>
          <Column width={80}>
            <HeaderCell>Synlig</HeaderCell>
            <Cell>
              {(rowData: any) => {
                return (
                  <StatusIndicator
                    status={
                      rowData.get("published") &&
                      new Date() >= (rowData.get("visible_from") as Date) &&
                      new Date() <= (rowData.get("visible_to") as Date)
                        ? "green"
                        : "yellow"
                    }
                    text={
                      rowData.get("published") &&
                      new Date() >= (rowData.get("visible_from") as Date) &&
                      new Date() <= (rowData.get("visible_to") as Date)
                        ? "Ja"
                        : "Nej"
                    }
                  />
                );
              }}
            </Cell>
          </Column>
          1
          <Column flexGrow={1} sortable>
            <HeaderCell>Synlig fra</HeaderCell>
            <Cell dataKey="visible_from">
              {(rowData: any) => {
                const dateStr = rowData.get("visible_from").toString();
                if (dateStr)
                  return moment(dateStr).format("DD/MM - YYYY HH:mm");
                else return null;
              }}
            </Cell>
          </Column>
          <Column flexGrow={1} sortable>
            <HeaderCell>Synlig til</HeaderCell>
            <Cell dataKey="visible_to">
              {(rowData: any) => {
                const dateStr = rowData.get("visible_to").toString();
                if (dateStr)
                  return moment(dateStr).format("DD/MM - YYYY HH:mm");
                else return null;
              }}
            </Cell>
          </Column>
          <Column flexGrow={1} sortable>
            <HeaderCell>Oprettet</HeaderCell>
            <Cell dataKey="createdAt">
              {(rowData: any) => {
                const dateStr = rowData.get("createdAt").toString();
                if (dateStr)
                  return moment(dateStr).format("DD/MM - YYYY HH:mm");
                else return null;
              }}
            </Cell>
          </Column>
        </Table>

        {!isLoading && data.length > 0 && !getAllClicked && (
          <div style={{ textAlign: "center", margin: "50px 0" }}>
            <Button onClick={this.getAllNotifications}>Vis alle</Button>
          </div>
        )}
      </div>
    );
  }
}

export default IndexBannerPage;
