import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';

@observer class SideMenu extends React.Component<any> {

    isRoute(routeName: string){
        return window.location.pathname.indexOf(routeName) > -1;
    }

    render() {
        return (
            <div className="side-menu">
                <ul>
                    <li>
                        <NavLink className={((navData: any) => navData.isActive ? "active" : "") + "rs-btn rs-btn-default" } to="/notifications">Notifikationer</NavLink>
                    </li>
                    <li>
                        <NavLink className={((navData: any) => navData.isActive ? "active" : "") + "rs-btn rs-btn-default" } to="/banners">Bannere</NavLink>
                    </li>
                    <li>
                        <NavLink className={((navData: any) => navData.isActive ? "active" : "") + "rs-btn rs-btn-default" } to="/consent-documents/terms-and-conditions">Vilkår og betingelser</NavLink>
                    </li>
                    <li>
                        <NavLink className={((navData: any) => navData.isActive ? "active" : "") + "rs-btn rs-btn-default" } to="/consent-documents/marketing">Samtykke for Markedsføring</NavLink>
                    </li>
                    <li>
                        <NavLink className={((navData: any) => navData.isActive ? "active" : "") + "rs-btn rs-btn-default" } to="/data-export">Data eksport</NavLink>
                    </li>
                </ul>
            </div>
        )
    }
}

export default SideMenu;