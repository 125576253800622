import { observer } from "mobx-react";
import React from "react";

interface Props {
  onChange: (filterValue: string) => void;
}

@observer
class BannerFilter extends React.Component<Props> {

  render() {
    const { onChange } = this.props;
    return (
      <div style={{display: 'block', overflow: 'hidden', marginBottom: 10}}>
        <select style={{width: 220, float: 'right', clear: 'both'}} onChange={(e) => onChange(e.target.value)}>
          <option value="all">Vis alle</option>
          <option value="visible">Vis kun synlige</option>
        </select>
      </div>
    );
  }
}

export default BannerFilter;
