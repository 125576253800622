import * as React from "react";
import { Parse } from "helpers/Parse";
import 'styles/public/consent-document.css';
import { Params } from "react-router-dom";
import { sanitize } from "dompurify";

interface Props {
  params: Params<string>;
}

interface State {
  data: string;
}

class PublicConsentDocument extends React.Component<Props, State> {
  async componentDidMount() {
    const query = new Parse.Query("ConsentDocument");
    const doc = await query.get(this.props.params.id as string);
    document.title = doc.get("name");
    this.setState({data: doc.get("body") })
  }

  render() {
    return (
      <>
      <style>
        {
          `h1,h2{
            padding-bottom: 1.25rem;
          }
          h3,h4,h5,h6{
            padding-top: .5rem;
            padding-bottom: 1rem;
          }
          ul, ol, p{
            padding-bottom: 1rem;
          }`
        }
        
      </style>
      <div dangerouslySetInnerHTML={{__html: sanitize(this.state?.data)}}></div>
      </>
    );
  }
}

export default PublicConsentDocument;
