import CenterContainer from "components/CenterContainer";
import _ from "lodash";
import * as React from "react";
import { Params } from "react-router";
import { ConsentDocumentFormDataInterface, consentDocumentStore, ConsentDocumentType } from "stores/ConsentDocumentStore";
import { navigationStore } from "stores/NavigationStore";
import ConsentDocumentForm from "../Form";

interface Props {
  params: Params<string>;
}

interface State {
  data: ConsentDocumentFormDataInterface | undefined;
}

class EditConsentDocumentPage extends React.Component<Props, State> {
  componentDidMount() {
    consentDocumentStore.get(this.props.params.id as string).then(() => {
      const data = consentDocumentStore.consentDocuments;
      const consentDocument = _.find(data, { id: this.props.params.id });
      const editData = !consentDocument
        ? undefined
        : ({
            id: consentDocument.id,
            name: consentDocument.get("name"),
            internalId: consentDocument.get("internalId"),
            type: consentDocument.get("type"),
            body: consentDocument.get("body"),
            publishedAt: consentDocument.get("publishedAt")
          } as ConsentDocumentFormDataInterface);
      this.setState({ data: editData });
    });
  }

  onDelete = async () => {
    if (window.confirm("Er du sikker på at du vil slette dette dokument?")) {
      await consentDocumentStore.delete(this.props.params.id as string);
      navigationStore.go(`/consent-documents/${this.props.params.type}`);
    }
  };

  onSubmit = async (data: ConsentDocumentFormDataInterface) => {
    await consentDocumentStore.update(data, false);
    navigationStore.go(`/consent-documents/${this.props.params.type}`);
  };

  onDuplicate = async (id: string) => {
    await consentDocumentStore.duplicate(id);
    navigationStore.go(`/consent-documents/${this.props.params.type}`);
  };

  onSavePublish = async (data: ConsentDocumentFormDataInterface) => {
    await consentDocumentStore.update(data, true);
    navigationStore.go(`/consent-documents/${this.props.params.type}`);
  };

  render() {
    if (!this.state?.data) return null;
    return (
      <CenterContainer title={`Rediger dokument "${this.state?.data.name}"`}>
        <ConsentDocumentForm
          type={this.props.params.type as ConsentDocumentType}
          onSubmit={this.onSubmit}
          onDelete={this.onDelete}
          onDuplicate={this.onDuplicate}
          onSavePublish={this.onSavePublish}
          editData={this.state.data}
        ></ConsentDocumentForm>
      </CenterContainer>
    );
  }
}

export default EditConsentDocumentPage;
