import Header from "components/Header";
import StatusIndicator from "components/StatusIndicator";
import { Parse } from "helpers/Parse";
import { observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import { Button, Table } from "rsuite";
import { audienceStore } from "stores/AudienceStore";
import { authStore } from "stores/AuthStore";
import { navigationStore } from "stores/NavigationStore";
import { notificationStore } from "stores/NotificationStore";
import { questionStore } from "stores/QuestionStore";

const { Column, HeaderCell, Cell } = Table;

interface State {
  getAllClicked: boolean;
  isLoading: boolean;
  data: any[];
}

@observer
class NotificationsPage extends React.Component<{}, State> {
  NOTIFICATIONS_DEFAULT = 20;
  NOTIFICATIONS_MAX = 9999;

  state = {
    getAllClicked: false,
    isLoading: true,
    data: [],
  };

  componentDidMount() {
    if (!Parse.User.current()) {
      navigationStore.go("/login");
    }

    audienceStore.getAudiences();
    questionStore.getQuestions();

    notificationStore.getAll(this.NOTIFICATIONS_DEFAULT).then(() => {
      this.setState({
        data: notificationStore.notifications,
        isLoading: false,
      });
    });
  }

  onRowClicked = (rowData: any) => {
    navigationStore.go(`/notifications/${rowData.id}`);
  };

  getAllNotifications = () => {
    this.setState({ isLoading: true });
    notificationStore.getAll(this.NOTIFICATIONS_MAX).then(() => {
      this.setState({
        getAllClicked: true,
        data: notificationStore.notifications,
        isLoading: false,
      });
    });
  };

  render() {
    if (!this.state) return;

    const data = this.state.data;
    const isLoading = this.state.isLoading;
    const getAllClicked = this.state.getAllClicked;

    return (
      <div>
        <Header>
          <Button
            color="red"
            onClick={() => navigationStore.go("/notifications/create")}
          >
            Opret ny notifikation
          </Button>

          <Button
            style={{ marginLeft: "20px" }}
            onClick={() => authStore.logout()}
          >
            Log ud
          </Button>
        </Header>

        <Table
          data={data}
          rowKey="id"
          rowHeight={50}
          onRowClick={this.onRowClicked}
          loading={isLoading}
          renderEmpty={() =>
            !isLoading ? (
              <div className="rs-table-body-info">Ingen notifications</div>
            ) : null
          }
          renderLoading={() => (
            <div className="rs-table-loader-wrapper">
              <div className="rs-table-loader">
                <i className="rs-table-loader-icon" />
                <span className="rs-table-loader-text">
                  Henter notifications...
                </span>
              </div>
            </div>
          )}
          autoHeight
        >
          <Column flexGrow={1}>
            <HeaderCell>Titel</HeaderCell>
            <Cell>
              {(rowData: any) => {
                return rowData.get("title");
              }}
            </Cell>
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Besked</HeaderCell>
            <Cell>
              {(rowData: any) => {
                return rowData.get("message");
              }}
            </Cell>
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Modtagere</HeaderCell>
            <Cell>
              {(rowData: any) => {
                const receiverType = rowData.get("receiver_type");
                switch (receiverType) {
                  case "all":
                    return "Alle";
                  case "segment-store":
                    return "Segment/butik";
                  case "specific-customer":
                    return "Specifikt medlem";
                  default:
                    return "Målgruppe"; // Legacy
                }
              }}
            </Cell>
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Publiceret</HeaderCell>
            <Cell>
              {(rowData: any) => {
                return rowData.get("published") ? "Ja" : "Nej (kladde)";
              }}
            </Cell>
          </Column>

          <Column width={150}>
            <HeaderCell>Sendes</HeaderCell>
            <Cell>
              {(rowData: any) => {
                const dateStr = rowData.get("schedule_time").toString();
                if (!rowData.get("published")) return "-";
                if (dateStr)
                  return moment(dateStr).format("DD/MM - YYYY HH:mm");
                else return null;
              }}
            </Cell>
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Vis popup</HeaderCell>
            <Cell>
              {(rowData: any) => {
                return rowData.get("show_popup") ? "Ja" : "Nej";
              }}
            </Cell>
          </Column>

          <Column width={80}>
            <HeaderCell>Sendt</HeaderCell>
            <Cell>
              {(rowData: any) => {
                return (
                  <StatusIndicator
                    status={rowData.get("is_sent") ? "green" : "yellow"}
                    text={rowData.get("is_sent") ? "Ja" : "Nej"}
                  />
                );
              }}
            </Cell>
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Status</HeaderCell>
            <Cell>
              {(rowData: any) => {
                return rowData.get("status_message") ?? "afventer";
              }}
            </Cell>
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Push</HeaderCell>
            <Cell>
              {(rowData: any) => {
                return rowData.get("no_push") === true
                  ? "Uden Push"
                  : "Med Push";
              }}
            </Cell>
          </Column>
        </Table>

        {!isLoading && data && data.length > 0 && !getAllClicked && (
          <div style={{ textAlign: "center", margin: "50px 0" }}>
            <Button onClick={this.getAllNotifications}>Vis alle</Button>
          </div>
        )}
      </div>
    );
  }
}

export default NotificationsPage;
