import * as React from 'react';
import { observer } from 'mobx-react';

import css from './style.module.css';

interface Props {
    errorMessage?: string
}

@observer class FormError extends React.Component<Props> {

    render() {
        const { errorMessage } = this.props;

        return (
            <span className={css.error}>
                { errorMessage ? `(${errorMessage})` : ''}
            </span>
        )
    }
}

export default FormError;