export function replaceObjectById(arr: Array<any>, obj: any): Array<any> {
    arr = arr.map((item) => {
        return item.id === obj.id ? obj : item;
    })

    return arr;
}

export function removeObjectById(arr: Array<any>, obj: any): Array<any> {
    arr = arr.filter((item) => {
        return item.id !== obj.id;
    })

    return arr;
}