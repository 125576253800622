import * as React from "react";
import { observer } from "mobx-react";
import Dropdown from "components/Forms/Dropdown";
import { FieldState } from "formstate";
import _ from "lodash";

interface Props {
  fieldState: FieldState<any>;
  disabled?: boolean;
}

@observer
class SelectDataExportType extends React.Component<Props> {
  render() {
    const { fieldState, disabled } = this.props;
    const data = [
      { name: "Marketing samtykke sendt til mParticle", value: "exportMParticleMarketingConsents" },
    ];

    const options = data.map((d) => ({
      value: d.value,
      label: d.name,
    }));

    return (
      <Dropdown
        disabled={disabled}
        label="Data type"
        placeholder="- Vælg"
        fieldState={fieldState}
        options={_.orderBy(options, "label")}
      />
    );
  }
}

export default SelectDataExportType;
