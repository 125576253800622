import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";

import "normalize.css";
import "rsuite/dist/rsuite.min.css";
import "styles/global.css";

if (
  window.location.protocol !== "https:" &&
  window.location.host.indexOf("localhost") === -1
) {
  window.location.href =
    "https:" + window.location.href.substring(window.location.protocol.length);
}

const rootElement = document.getElementById("root") as Element;
const root = createRoot(rootElement);
root.render(<App />);