import * as React from 'react';
import { observer } from 'mobx-react';

interface Props {
    status: 'green' | 'yellow',
    text: any
}
@observer class StatusIndicator extends React.Component<Props> {
    render() {
        const { status, text } = this.props;
        return(
            <div className="status-indicator"><span className={status}></span>{ text }</div>
        )
    }
}

export default StatusIndicator;