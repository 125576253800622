import * as React from "react";
import { observer } from "mobx-react";

@observer
class PageContainer extends React.Component<any> {
  render() {
    const { children } = this.props;
    return <div className="page-container">{children}</div>;
  }
}

export default PageContainer;
