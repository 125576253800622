import { removeObjectById, replaceObjectById } from "helpers/CollectionHelper";
import LoadPromise from "helpers/LoadPromise";
import { Parse } from "helpers/Parse";
import _ from "lodash";
import { action, observable } from "mobx";
import { navigationStore } from "stores/NavigationStore";

export const ConsentDocumentParseName = "ConsentDocument";
export type ConsentDocumentType = "terms-and-conditions" | "marketing";
export const ConsentDocumentTypeLabel = {
  "terms-and-conditions": "Vilkår og betingelser",
  marketing: "Samtykke for markedsføring",
};

export interface ConsentDocumentFormDataInterface {
  id?: any;
  internalId: string;
  type: ConsentDocumentType;
  name: string;
  body: string;
  required: boolean;
  publishedAt: Date | undefined | null;
}

export class ConsentDocumentStore {
  @observable consentDocuments: Array<Parse.Object> = [];

  @action
  getAll = (limit: number = 9999, type: ConsentDocumentType, options?: any) => {
    return LoadPromise(async (resolve, reject) => {
      if (!Parse.User.current()) {
        navigationStore.go("/login");
        resolve();
        return;
      }
      const query = new Parse.Query(ConsentDocumentParseName);
      query.equalTo("type", type);
      query.addDescending("createdAt");
      query.addDescending("publishedAt");

      query.limit(limit);
      const res = await query.find();
      this.consentDocuments = res;
      resolve();
    });
  };

  @action
  get = (id: string) => {
    return LoadPromise(async (resolve, reject) => {
      const existingNotification = _.find(this.consentDocuments, { id: id });
      if (existingNotification) {
        resolve(existingNotification);
      } else {
        const query = new Parse.Query(ConsentDocumentParseName);
        const res = await query.get(id);
        if (res) {
          this.consentDocuments.push(res);
          resolve(res);
        }
      }
    });
  };

  @action
  create = (data: ConsentDocumentFormDataInterface) => {
    return LoadPromise(async (resolve, reject) => {
      const ConsentDocument = Parse.Object.extend(ConsentDocumentParseName);
      const consentDocument = new ConsentDocument();
      this._mapToParse(consentDocument, data);
      const savedObj = await consentDocument.save();
      this.consentDocuments.unshift(savedObj);
      resolve(savedObj);
    });
  };

  @action
  duplicate = (id: string) => {
    return LoadPromise(async (resolve) => {
      const query = new Parse.Query(ConsentDocumentParseName);
      const res = await query.get(id);

      const ConsentDocument = Parse.Object.extend(ConsentDocumentParseName);
      const consentDocument = new ConsentDocument();

      let objectJSON = res.toJSON() as any;
      objectJSON.name = "(KOPI) " + objectJSON.name;
      objectJSON.internalId = "copy_" + objectJSON.internalId;
      delete objectJSON.objectId;
      delete objectJSON.created_by;
      delete objectJSON.publishedAt;
      consentDocument.set(objectJSON);
      const savedObj = await consentDocument.save();
      this.consentDocuments.unshift(savedObj);
      resolve(savedObj);
    });
  };

  _mapToParse = (
    consentDocument: Parse.Object,
    data: ConsentDocumentFormDataInterface
  ) => {
    consentDocument.set("internalId", data.internalId);
    consentDocument.set("type", data.type);
    consentDocument.set("name", data.name);
    consentDocument.set("body", data.body);
    consentDocument.set("required", data.type === "terms-and-conditions");
    consentDocument.set("publishedAt", data.publishedAt);
    return consentDocument;
  };

  @action
  publish = (id: string) => {
    return LoadPromise(async (resolve, reject) => {
      try {
        const query = new Parse.Query(ConsentDocumentParseName);
        const consentDocument = await query.get(id);
        consentDocument.set("publishedAt", new Date());
        const savedObj = await consentDocument.save();
        this.consentDocuments = replaceObjectById(
          this.consentDocuments,
          savedObj
        );
        resolve(savedObj);
      } catch (err) {
        reject(err);
      }
    });
  };

  @action
  update = (data: ConsentDocumentFormDataInterface, publish: boolean) => {
    return LoadPromise(async (resolve, reject) => {
      const query = new Parse.Query(ConsentDocumentParseName);
      const consentDocument = await query.get(data.id);
      if(publish) data.publishedAt = new Date();
      this._mapToParse(consentDocument, data);
      try {
        const savedObj = await consentDocument.save();
        this.consentDocuments = replaceObjectById(
          this.consentDocuments,
          savedObj
        );
        resolve(savedObj);
      } catch (err) {
        reject(err);
      }
    });
  };

  @action
  delete = (id: string) => {
    return LoadPromise(async (resolve, reject) => {
      const query = new Parse.Query(ConsentDocumentParseName);
      const res = await query.get(id);
      if (res) {
        await res.destroy();
        this.consentDocuments = removeObjectById(this.consentDocuments, res);
        resolve();
      } else reject();
    });
  };
}

export const consentDocumentStore = new ConsentDocumentStore();
