import * as React from 'react';
import { observer } from 'mobx-react';
import Logo from 'components/Logo';

interface Props {
    title?: string,
    showLogo?: boolean
    children: any
}

@observer class LoginContainer extends React.Component<Props> {

    render() {
        const { children, title, showLogo } = this.props;

        return (
            <div style={{ width: '100%', maxWidth: '500px', margin: '0 auto', paddingTop: '100px' }}>

                <div style={{ textAlign: 'center', marginBottom: '50px' }}>
                    {
                        showLogo &&
                        <div style={{ textAlign: 'center', marginBottom: '50px' }}>
                            <Logo width='120' />
                        </div>
                    }

                    {
                        title &&
                        <h1 style={{ fontSize: '22px' }}>{title}</h1>
                    }

                </div>

                {children}
            </div>
        )
    }
}

export default LoginContainer;