import CreateBannerPage from "components/Banners/Create";
import EditBannerPage from "components/Banners/Edit";
import IndexBannerPage from "components/Banners/Index";
import CreateConsentDocumentPage from "components/Pages/ConsentDocument/Create";
import EditConsentDocumentPage from "components/Pages/ConsentDocument/Edit";
import IndexConsentDocumentPage from "components/Pages/ConsentDocument/Index";
import PublicConsentDocument from "components/Pages/ConsentDocument/Public";
import CreateNotificationPage from "components/Pages/CreateNotificationPage";
import DashboardPage from "components/Pages/DashboardPage";
import DataExportPage from "components/Pages/DataExportPage";
import EditNotificationPage from "components/Pages/EditNotificationPage";
import LoginPage from "components/Pages/LoginPage";
import NotificationsPage from "components/Pages/NotificationsPage";
import Toast from "components/Toast";
import React from "react";
import { useParams } from "react-router";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";

export default function App() {
  return (
    <>
      <Toast />
      <HashRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/public/consent-documents/:id"
            element={<PublicConsentDocumentWrapper />}
          />
          <Route path="/" element={<Navigate to="/notifications" />} />
          <Route element={<DashboardPage />}>
            <Route path="/notifications" element={<NotificationsPage />} />
            <Route
              path="/notifications/create"
              element={<CreateNotificationPage />}
            />
            <Route
              path="/notifications/:id"
              element={<EditNotificationPageWrapper />}
            />
            <Route path="/banners" element={<IndexBannerPage />} />
            <Route path="/banners/create" element={<CreateBannerPage />} />
            <Route path="/banners/:id" element={<EditBannerPageWrapper />} />
            <Route
              path="/consent-documents/:type"
              element={<IndexConsentDocumentPageWrapper />}
            />
            <Route
              path="/consent-documents/:type/create"
              element={<CreateConsentDocumentPageWrapper />}
            />
            <Route
              path="/consent-documents/:type/:id"
              element={<EditConsentDocumentPageWrapper />}
            />
            <Route
              path="/data-export"
              element={<DataExportPage />}
            />
          </Route>
        </Routes>
      </HashRouter>
    </>
  );
}

function PublicConsentDocumentWrapper() {
  const params = useParams();
  return <PublicConsentDocument params={params} />;
}

function EditBannerPageWrapper() {
  const params = useParams();
  return <EditBannerPage params={params} />;
}

function EditNotificationPageWrapper() {
  const params = useParams();
  return <EditNotificationPage params={params} />;
}

function IndexConsentDocumentPageWrapper() {
  const params = useParams();
  return <IndexConsentDocumentPage params={params} />;
}

function CreateConsentDocumentPageWrapper() {
  const params = useParams();
  return <CreateConsentDocumentPage params={params} />;
}

function EditConsentDocumentPageWrapper() {
  const params = useParams();
  return <EditConsentDocumentPage params={params} />;
}