import CenterContainer from "components/CenterContainer";
import { observer } from "mobx-react";
import * as React from "react";
import { BannerFormDataInterface, bannerStore } from "stores/BannerStore";
import { navigationStore } from "stores/NavigationStore";
import BannerForm from "../Form";

@observer
class CreateBannerPage extends React.Component<any> {
  onSubmit = async (data: BannerFormDataInterface) => {
    await bannerStore.create(data);
    navigationStore.go("/banners");
  };

  render() {
    return (
      <CenterContainer title="Opret nyt banner">
        <BannerForm onSubmit={this.onSubmit} />
      </CenterContainer>
    );
  }
}

export default CreateBannerPage;
