import * as React from 'react';
import { observer } from 'mobx-react';
import Dropdown from 'components/Forms/Dropdown';
import { FieldState } from 'formstate';
import _ from 'lodash';

interface Props {
    fieldState: FieldState<any>,
    disabled?: boolean
}

@observer class SelectReceiver extends React.Component<Props> {

    render() {
        const { fieldState, disabled } = this.props;
        const data = [{ value: "all", label: "Alle" }, { value: "segment-store", label: "Segmenter & butikker" }, { value: "specific-customer", label: "Specifikt medlem" }];

        return (
            <Dropdown
                disabled={disabled}
                label='Modtagere (*påkrævet)'
                placeholder='- Vælg'
                fieldState={fieldState}
                options={_.orderBy(data, 'label')}
            />
        )
    }
}

export default SelectReceiver;