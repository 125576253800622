import { Validator } from "formstate";
export const requiredValidator = (val: any) => !val && "Skal udfyldes";

export const numbersOrSemicolonValidator: Validator<any> = (value: any) => {
  if (!value) return null;
  if (!/^[0-9]{10}(?:;[0-9]{10})*$/g.exec(value)) {
    return "Ugyldigt format. Korrekt format er: 45XXXXXXXX eller 45XXXXXXXX;45XXXXXXXX";
  }
};

export const bannerFormatValidator: Validator<any> = (value: File) => {
  if (!value) return null;
  if(value.size > 1000000) return "Billedet er for stort. Maks 1MB";
  const url = URL.createObjectURL(value);
  return reactImageSize(url).then((elem) => {
    if ((elem.width > 1920 || elem.height > 1080) || ratio(elem.width, elem.height) !== 1.78) {
      return "Ugyldig billedestørrelse. Skal være 16:9 format og maks 1920x1080";
    } else {
      return null;
    }
  });
};

export const isEmailValidator: Validator<any> = (value: string) => {
  // Empty values are not invalid emails
  if (!value) return null;
  if (requiredValidator(value)) return null;
  value = value.trim();
  // Src : http://emailregex.com/
  //eslint-disable-next-line
  if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.exec(
      value
    )
  ) {
    return "Ugyldig email";
  }
  return null;
};

const ratio = (width: number, height: number) => {
  return Math.round((width / height) * 100) / 100;
};

const reactImageSize = (url: string) =>
  new Promise<{ width: number; height: number }>((resolve, reject) => {
    const img = new Image();
    img.addEventListener("load", () => {
      resolve({ width: img.width, height: img.height });
    });
    img.src = url;
});
