import * as React from 'react';
import { observer } from 'mobx-react';
import { navigationStore } from 'stores/NavigationStore';
import { Parse } from 'helpers/Parse';
import GlobalContainer from 'components/GlobalContainer';
import SideMenu from 'components/SideMenu';
import PageContainer from 'components/PageContainer';
import { Outlet } from 'react-router';

@observer class DashboardPage extends React.Component {
    constructor(props: any) {
        super(props);
        if (!Parse.User.current()) {
            navigationStore.go('/login');
        }
    }
    render() {
        return (
            <GlobalContainer>
                <SideMenu></SideMenu>
                <PageContainer><Outlet /></PageContainer>
            </GlobalContainer>
        )
    }
}

export default DashboardPage;