import CenterContainer from "components/CenterContainer";
import _ from "lodash";
import * as React from "react";
import { Params } from "react-router";
import { BannerFormDataInterface, bannerStore } from "stores/BannerStore";
import { navigationStore } from "stores/NavigationStore";
import BannerForm from "../Form";

interface Props {
  params: Params<string>;
}

interface State {
  data: BannerFormDataInterface | undefined;
}

class EditBannerPage extends React.Component<Props, State> {
  componentDidMount() {
    bannerStore.get(this.props.params.id as string).then(() => {
      const data = bannerStore.banners;
      const banner = _.find(data, { id: this.props.params.id });
      const editData = !banner
        ? undefined
        : ({
            id: banner.id,
            parseObject: banner,
            title: banner.get("title"),
            ctaText: banner.get("cta_text"),
            sortOrder: banner.get("sort_order"),
            receiverType: banner.get("receiver_type"),
            stores: banner.get("stores"),
            file: banner.get("file"),
            existingFileUrl: (banner.get("file") as Parse.File).url(),
            segments: banner.get("segments"),
            phoneNumbers: (banner.get("phone_numbers") ?? []).join(";"),
            visibleFrom: banner.get("visible_from"),
            visibleTo: banner.get("visible_to"),
            url: banner.get("url"),
            published: banner.get("published"),
            noMemberCardOnly: banner.get("no_member_card_only")
          } as BannerFormDataInterface);
      this.setState({ data: editData });
    });
  }

  onDelete = async () => {
    if (window.confirm("Er du sikker på at du vil slette dette banner?")) {
      await bannerStore.delete(this.props.params.id as string);
      navigationStore.go("/banners");
    }
  };

  onSubmit = async (data: BannerFormDataInterface) => {
    await bannerStore.update(data);
    navigationStore.go("/banners");
  };

  onDuplicate = async (id: string) => {
    await bannerStore.duplicate(id);
    navigationStore.go("/banners");
  };

  render() {
    if (!this.state?.data) return null;
    return (
      <CenterContainer title={"Rediger banner"}>
        <BannerForm
          onSubmit={this.onSubmit}
          onDelete={this.onDelete}
          onDuplicate={this.onDuplicate}
          editData={this.state.data}
        ></BannerForm>
      </CenterContainer>
    );
  }
}

export default EditBannerPage;
