import Header from "components/Header";
import SelectDataExportType from "components/SelectDataExportType";
import { FieldState, FormState } from "formstate";
import { requiredValidator } from "helpers/FormValidation";
import { Parse } from "helpers/Parse";
import { observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import { Button, DateRangePicker } from "rsuite";
import { authStore } from "stores/AuthStore";

@observer
class DataExportPage extends React.Component {
  form = new FormState({
    exportType: new FieldState("")
      .validators(requiredValidator)
      .disableAutoValidation(),
    from: new FieldState(moment().startOf("month").toDate())
      .validators(requiredValidator)
      .disableAutoValidation(),
    to: new FieldState(moment().toDate())
      .validators(requiredValidator)
      .disableAutoValidation(),
  });
  loading = false;

  render() {
    const form = this.form.$;

    return (
      <>
        <div>
          <Header>
            <Button
              style={{ marginLeft: "20px" }}
              onClick={() => authStore.logout()}
            >
              Log ud
            </Button>
          </Header>
          <h5>Vælg data type</h5>
          <div>
            <SelectDataExportType
              disabled={false}
              fieldState={this.form.$.exportType}
            />
          </div>

          <br />
          <h5>Vælg periode</h5>
          <div style={{ marginTop: "20px" }}>
            <DateRangePicker
              defaultValue={[
                moment().startOf("month").toDate(),
                moment().toDate(),
              ]}
              onChange={(d) => {
                form.from.value = d?.[0] as Date;
                form.to.value = d?.[1] as Date;
              }}
            />
          </div>

          <Button
            color="red"
            disabled={
              !form.exportType.value ||
              form.exportType.value === "-1"
            }
            loading={this.loading}
            style={{ marginTop: "40px" }}
            onClick={() => { this.loading = true; this.export()  }}
          >
            Eksportér CSV
          </Button>
        </div>
      </>
    );
  }

  async export() {
    this.loading = true;
    var data = await Parse.Cloud.run(this.form.$.exportType.value, {
      from: this.form.$.from.value,
      to: this.form.$.to.value,
    });

    let csvContent = "data:text/csv;charset=utf-8," + data;
    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
    this.loading = false;
  }
}

export default DataExportPage;
