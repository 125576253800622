import * as React from "react";
import { observe } from "mobx";
import { observer } from "mobx-react";
import NotificationSystem from "react-notification-system";
import { toastStore } from "stores/ToastStore";

@observer
export default class Toast extends React.Component {
  toastRef: any;

  constructor(props: any) {
    super(props);
    this.toastRef = React.createRef();
  }

  componentDidMount() {
    observe(toastStore.toasts, (d) => {
      if (toastStore.toasts.length > 0) {
        toastStore.toasts.forEach((toast) => {
          this.toastRef.current.addNotification(toast);
        });
      }

      toastStore.resetToasts();
    });
  }

  render() {
    return <NotificationSystem ref={this.toastRef as any} />;
  }
}
