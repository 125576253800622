import { action, observable } from 'mobx';
import { Parse } from 'helpers/Parse';
import LoadPromise from "helpers/LoadPromise";

class StoreStore {

    @observable stores: Array<Parse.Object> = [];

    loaded: boolean = false;

    @action
    getStores = () => {
        return LoadPromise(async (resolve, reject) => {
            if (this.loaded) {
                resolve();
                return;
            }

            const query = new Parse.Query('Store');
            query.limit(99999);
            query.equalTo("hidden", false);

            const res = await query.find();
            this.stores = res;
            
            this.loaded = true;
            resolve();
        })
    }
    
}

export const storeStore = new StoreStore();