import Form from "components/Forms/Form";
import Input from "components/Forms/Input";
import LoginContainer from "components/LoginContainer";
import { FieldState, FormState } from "formstate";
import { requiredValidator } from "helpers/FormValidation";
import { Parse } from "helpers/Parse";
import { observer } from "mobx-react";
import * as React from "react";
import { Button } from "rsuite";
import { authStore } from "stores/AuthStore";
import { loadingStore } from "stores/LoadingStore";
import { navigationStore } from "stores/NavigationStore";

@observer
class LoginPage extends React.Component {
  form = new FormState({
    email: new FieldState("")
      .validators(requiredValidator)
      .disableAutoValidation(),
    password: new FieldState("")
      .validators(requiredValidator)
      .disableAutoValidation(),
  });

  constructor(props: any) {
    super(props);

    if (Parse.User.current()) {
      navigationStore.go("/");
    }
  }

  onSubmit = () => {
    const form = this.form.$;
    authStore.login(form.email.$, form.password.$);
    form.password.reset();
  };

  render() {
    const form = this.form.$;
    const isLoading = loadingStore.isLoading;

    return (
      <LoginContainer showLogo={true}>
        <Form onValidSubmit={this.onSubmit} formState={this.form}>
          <Input
            label="Telefonnummer"
            fieldState={form.email}
            placeholder="ex 4543301404"
          />

          <Input
            label="Pinkode (4-cifre)"
            type="password"
            fieldState={form.password}
            placeholder="Pin-kode"
          />

          <div style={{ textAlign: "right", marginTop: "20px" }}>
            <Button color="red" type="submit" loading={isLoading}>
              Log ind
            </Button>
          </div>
        </Form>
      </LoginContainer>
    );
  }
}

export default LoginPage;
