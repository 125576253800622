import * as React from "react";
import { observer } from "mobx-react";
import Dropdown from "components/Forms/Dropdown";
import { FieldState } from "formstate";
import _ from "lodash";

interface Props {
  fieldState: FieldState<any>;
  disabled?: boolean;
}

@observer
class SelectNotificationIcon extends React.Component<Props> {
  render() {
    const { fieldState, disabled } = this.props;
    const data = [
      { name: "Find butik", value: "notification_store" },
      { name: "Indkøbsliste", value: "notification_shoppinglist" },
      { name: "Inspiration", value: "notification_inspiration" },
      { name: "Kundekort", value: "notification_membercard" },
      { name: "Nyheder", value: "notification_news" },
      { name: "Profil", value: "notification_profile" },
      { name: "Tilbud", value: "notification_product_offers" },
      { name: "DGFS Besked", value: "notification_info" },
    ];

    const options = data.map((d) => ({
      value: d.value,
      label: d.name,
    }));

    return (
      <Dropdown
        disabled={disabled}
        label="Notifikation Ikon"
        placeholder="- Vælg"
        fieldState={fieldState}
        options={_.orderBy(options, "label")}
      />
    );
  }
}

export default SelectNotificationIcon;
