import { observable, action } from "mobx";
import { Parse } from 'helpers/Parse';
import LoadPromise from "helpers/LoadPromise";
import { navigationStore } from 'stores/NavigationStore';
import _ from 'lodash';
import { NotificationFormDataInterface } from "components/NotificationForm";
import { replaceObjectById, removeObjectById } from "helpers/CollectionHelper";

export class NotificationStore {

    @observable notifications: Array<Parse.Object> = [];

    @action
    getAll = (limit: number = 9999) => {
        return LoadPromise(async (resolve) => {
            if (!Parse.User.current()) {
                navigationStore.go('/login');
                resolve();
                return;
            }
            const query = new Parse.Query('NotificationQueue');
            query.descending('_created_at');
            query.limit(limit);
            const res = await query.find();
            this.notifications = res;
            resolve(this.notifications);
        })
    }

    @action
    get = (id: string) => {
        return LoadPromise(async (resolve) => {
            const existingNotification = _.find(this.notifications, { id: id });
            if (existingNotification) {
                resolve(existingNotification);
            }
            else {
                const query = new Parse.Query('NotificationQueue');
                const res = await query.get(id);
                if (res) {
                    this.notifications.push(res);
                    resolve(res);
                }
            }
        })
    }

    @action
    create = (data: NotificationFormDataInterface) => {
        return LoadPromise(async (resolve, reject) => {
            const NotificationQueue = Parse.Object.extend('NotificationQueue');
            const notificationQueue = new NotificationQueue();
            this._mapToParse(notificationQueue, data);

            const savedObj = await notificationQueue.save();
            this.notifications.unshift(savedObj);
            resolve(savedObj);
        })
    }

    @action
    duplicate = (id: string) => {
        return LoadPromise(async (resolve) => {
            const query = new Parse.Query('NotificationQueue');
            const res = await query.get(id);

            const NotificationQueue = Parse.Object.extend('NotificationQueue');
            const notificationQueue = new NotificationQueue();

            let objectJSON = res.toJSON() as any;
            objectJSON.title = '(KOPI) '+objectJSON.title;
            delete objectJSON.objectId;
            delete objectJSON.created_by;
            objectJSON.published = false;
            objectJSON.is_sent = false;
            objectJSON.status_message = null;
            notificationQueue.set(objectJSON);
            const savedObj = await notificationQueue.save();
            this.notifications.unshift(savedObj);
            resolve(savedObj);
        })
    }


    _mapToParse = (notificationQueue: Parse.Object, data: NotificationFormDataInterface) => {
        notificationQueue.set('title', data.title);
        notificationQueue.set('message', data.message);
        notificationQueue.set('url', data.url);
        notificationQueue.set('receiver_type', data.receiverType);
        notificationQueue.set('store_ids', data.storeIds);
        notificationQueue.set('segment_ids', data.segmentIds);
        notificationQueue.set('phone_number', data.phoneNumber?.replace(/\s/, ''));
        notificationQueue.set('questionId', data.questionId);
        notificationQueue.set('notification_icon_type', data.notification_icon_type);
        notificationQueue.set('schedule_time', data.scheduleTime);
        notificationQueue.set('no_push', ((data.pushType === "true") ? true : false));
        notificationQueue.set('published', data.published);
        notificationQueue.set('show_popup', data.showPopup);

        if(data.receiverType !== "segment-store"){
            notificationQueue.set('segment_ids', null);
            notificationQueue.set('store_ids', null);
        }

        if(data.receiverType !== "specific-customer"){
            notificationQueue.set('phone_number', null);
        }

        return notificationQueue;
    }

    @action
    update = (data: NotificationFormDataInterface) => {
        return LoadPromise(async (resolve, reject) => {
            const query = new Parse.Query('NotificationQueue');
            const notificationQueue = await query.get(data.id);

            this._mapToParse(notificationQueue, data);

            const savedObj = await notificationQueue.save();
            this.notifications = replaceObjectById(this.notifications, savedObj);
            resolve(savedObj);
        })
    }

    @action
    delete = (id: string) => {
        return LoadPromise(async (resolve, reject) => {
            const query = new Parse.Query('NotificationQueue');
            const res = await query.get(id);
            if (res) {
                await res.destroy();
                this.notifications = removeObjectById(this.notifications, res);
                resolve();
            }
            else reject();
        })
    }
}

export const notificationStore = new NotificationStore();