import { action, observable } from 'mobx';
import { Parse } from 'helpers/Parse';
import LoadPromise from "helpers/LoadPromise";

class AudienceStore {

    @observable audiences: Array<Parse.Object> = [];

    loaded: boolean = false;

    @action
    getAudiences = () => {
        return LoadPromise(async (resolve, reject) => {
            if (this.loaded) {
                resolve();
                return;
            }

            const query = new Parse.Query('_Audience');
            query.limit(99999);

            const res = await query.find();
            this.audiences = res;
            
            this.loaded = true;
            resolve();
        })
    }
    
}

export const audienceStore = new AudienceStore();