import * as React from 'react';
import { observer } from 'mobx-react';

import style from './style.module.css';

interface Props {
    children: any
}

@observer class GlobalContainer extends React.Component<Props> {

    render() {
        const { children } = this.props;

        return (
            <div className={style.container}>
                {children}
            </div>
        )
    }
}

export default GlobalContainer;