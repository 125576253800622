import * as React from 'react';
import { observer } from 'mobx-react';

import logoSrc from './logo.png';

interface Props {
    width?: string,
    height?: string
}

@observer class Logo extends React.Component<Props> {

    render() {
        const { width, height } = this.props;

        return (
            <img
                src={logoSrc}
                alt="Dagrofa FoodService"
                width={width}
                height={height}
            />
        )
    }
}

export default Logo;