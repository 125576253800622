import CenterContainer from "components/CenterContainer";
import NotificationForm, { NotificationFormDataInterface } from "components/NotificationForm";
import * as React from "react";
import { navigationStore } from "stores/NavigationStore";
import { notificationStore } from "stores/NotificationStore";

export default function CreateNotificationPage() {
  const onSubmit = async (data: NotificationFormDataInterface) => {
    await notificationStore.create(data);
    navigationStore.go("/");
  };

  return (
    <CenterContainer title="Opret ny notifikation">
      <NotificationForm onSubmit={onSubmit} />
    </CenterContainer>
  );
}
