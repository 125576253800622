import * as Parse from 'parse';

const appId = process.env.REACT_APP_PARSE_APPLICATION_ID as string;
const key = process.env.REACT_APP_PARSE_JAVASCRIPT_KEY as string;
Parse.initialize(appId, key);

(Parse as any).serverURL = process.env.REACT_APP_PARSE_SERVER_URL;

function Pointer(className: string, objectId: string) {
    return {
        __type: 'Pointer',
        className: className,
        objectId: objectId
    }
}

function GetQueryPointer(className: string, objectId: string) {
    var Obj = Parse.Object.extend(className);
    var obj = new Obj();
    obj.id = objectId;

    return obj;
}

function toParseObject(obj: any, className: string) {
    if (!obj) return obj;
    obj.className = className;
    return Parse.Object.fromJSON(obj, true);
}

export {
    Parse,
    Pointer,
    GetQueryPointer,
    toParseObject
}