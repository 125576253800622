import Header from "components/Header";
import StatusIndicator from "components/StatusIndicator";
import { Parse } from "helpers/Parse";
import { observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import { Params } from "react-router";
import { Button, Table } from "rsuite";
import { authStore } from "stores/AuthStore";
import {
  consentDocumentStore,
  ConsentDocumentType,
} from "stores/ConsentDocumentStore";
import { navigationStore } from "stores/NavigationStore";

const { Column, HeaderCell, Cell } = Table;

interface Props {
  params: Params<string>;
}

interface State {
  isLoading: boolean | undefined;
  data: any[] | undefined;
}

@observer
class IndexConsentDocumentPage extends React.Component<Props, State> {

  componentDidMount() {
    if (!Parse.User.current()) {
      navigationStore.go("/login");
    }
    this.loadDataForType(this.props.params.type as ConsentDocumentType);
  }

  componentWillReceiveProps(nextProps: any) {
    this.loadDataForType(nextProps.params.type);
  }

  loadDataForType(type: ConsentDocumentType) {
    consentDocumentStore
      .getAll(999, type as ConsentDocumentType, null)
      .then(() => {
        this.setState({
          data: consentDocumentStore.consentDocuments,
          isLoading: false,
        });
      });
  }

  onRowClicked = (rowData: any) => {
    navigationStore.go(
      `/consent-documents/${this.props.params.type}/${rowData.id}`
    );
  };

  isActual(item: any){
    const publishedAt = new Date(item.get("publishedAt"));
    if(!publishedAt) return false;
    const items = consentDocumentStore.consentDocuments.filter(x => x.get("type") === this.props.params.type && x.get("publishedAt"));
    var publishedTimeStamps = items.map(x => (new Date(x.get("publishedAt"))).getTime());
    return publishedAt.getTime() === Math.max(...publishedTimeStamps);
  }

  render() {
    const { data, isLoading } = this.state ?? {};

    return (
      <div>
        <Header>
          <Button
            color="red"
            onClick={() =>
              navigationStore.go(
                "/consent-documents/" + this.props.params.type + "/create"
              )
            }
          >
            Opret nyt dokument
          </Button>

          <Button
            style={{ marginLeft: "20px" }}
            onClick={() => authStore.logout()}
          >
            Log ud
          </Button>
        </Header>
        <Table
          data={data}
          rowKey="id"
          rowHeight={90}
          onRowClick={this.onRowClicked}
          loading={isLoading}
          renderEmpty={() =>
            !isLoading ? (
              <div className="rs-table-body-info">Ingen dokumenter</div>
            ) : null
          }
          renderLoading={() => (
            <div className="rs-table-loader-wrapper">
              <div className="rs-table-loader">
                <i className="rs-table-loader-icon" />
                <span className="rs-table-loader-text">
                  Henter dokumenter...
                </span>
              </div>
            </div>
          )}
          autoHeight
        >
        <Column flexGrow={1}>
            <HeaderCell>Synlig i app</HeaderCell>
            <Cell>
            {(rowData: any) => {
                return (
                  <StatusIndicator
                    status={
                      this.isActual(rowData)
                        ? "green"
                        : "yellow"
                    }
                    text={
                      this.isActual(rowData)
                        ? "Ja"
                        : "Nej"
                    }
                  />
                );
              }}
            </Cell>
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Navn</HeaderCell>
            <Cell>
              {(rowData: any) => {
                return (
                  <>
                    {!rowData.get("publishedAt")
                      ? "(Kladde) " + rowData.get("name")
                      : rowData.get("name")}
                  </>
                );
              }}
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Internt ID</HeaderCell>
            <Cell>
              {(rowData: any) => {
                return rowData.get("internalId");
              }}
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Udgivet d.</HeaderCell>
            <Cell dataKey="publishedAt">
              {(rowData: any) => {
                const dateStr = rowData.get("publishedAt")?.toString();
                if (dateStr)
                  return moment(dateStr).format("DD/MM - YYYY HH:mm");
                else return null;
              }}
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Oprettet</HeaderCell>
            <Cell dataKey="createdAt">
              {(rowData: any) => {
                const dateStr = rowData.get("createdAt").toString();
                if (dateStr)
                  return moment(dateStr).format("DD/MM - YYYY HH:mm");
                else return null;
              }}
            </Cell>
          </Column>
        </Table>
      </div>
    );
  }
}

export default IndexConsentDocumentPage;
