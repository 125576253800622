import { action, observable } from 'mobx';
import { createBrowserHistory } from 'history';

export enum HeaderEnum {
    NOTIFICATIONS = 'NOTIFICATIONS'
}

export class NavigationStore {
    @observable activeHeader: HeaderEnum | null = HeaderEnum.NOTIFICATIONS;

    history = createBrowserHistory();

    constructor() {
        this.setActiveHeaderByCurrentBrowserLocation();
    }

    @action
    push(location: string, callback: Function | undefined = undefined) {
        this.history.push(location);
        this.setActiveHeaderByCurrentBrowserLocation();

        if (callback) {
            callback();
        }
    }

    @action
    goBack() {
        this.history.back();
        this.setActiveHeaderByCurrentBrowserLocation();
    }

    @action
    replace(location: string) {
        this.history.replace(location);
        this.setActiveHeaderByCurrentBrowserLocation();
    }

    @action
    go(location: string) {
        window.location.href = `/#${location}`;
    }

    @action
    setActiveHeader(header: HeaderEnum | null) {
        if (header !== this.activeHeader) {
            this.activeHeader = header;
        }
    }

    setActiveHeaderByCurrentBrowserLocation() {
        if (window.location.pathname === '/' || window.location.pathname === '')  this.setActiveHeader(HeaderEnum.NOTIFICATIONS);
        else this.setActiveHeader(null)
    }

}

export const navigationStore = new NavigationStore();