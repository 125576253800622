import { observable, action } from "mobx";
import { Parse } from "helpers/Parse";
import LoadPromise from "helpers/LoadPromise";
import { navigationStore } from "stores/NavigationStore";
import _ from "lodash";
import { replaceObjectById, removeObjectById } from "helpers/CollectionHelper";

export interface BannerFormDataInterface {
  id?: any;
  parseObject?: any;
  title: string;
  url?: string;
  ctaText: string;
  sortOrder: number;
  receiverType: "all" | "segment-store" | "specific-customer";
  stores?: string[];
  segments?: string[];
  phoneNumbers?: string;
  file: File;
  existingFileUrl: string;
  noMemberCardOnly: boolean;
  published: boolean;
  visibleFrom: any;
  visibleTo: any;
}

export class BannerStore {
  @observable banners: Array<Parse.Object> = [];

  @action
  getAll = (limit: number = 9999, options?: any) => {
    return LoadPromise(async (resolve, reject) => {
      if (!Parse.User.current()) {
        navigationStore.go("/login");
        resolve();
        return;
      }
      const query = new Parse.Query("Banner");

      if (options) {
        if(options.sortColumn) options.sortType === "asc" ? query.ascending(options.sortColumn) : query.descending(options.sortColumn);
        if(options.filterValue === 'visible'){
          query.equalTo('published', true);
          query.lessThanOrEqualTo('visible_from', new Date());
          query.greaterThanOrEqualTo('visible_to', new Date());
        }
      }

      query.limit(limit);
      const res = await query.find();
      this.banners = res;
      resolve();
    });
  };

  @action
  get = (id: string) => {
    return LoadPromise(async (resolve, reject) => {
      const existingNotification = _.find(this.banners, { id: id });
      if (existingNotification) {
        resolve(existingNotification);
      } else {
        const query = new Parse.Query("Banner");
        const res = await query.get(id);
        if (res) {
          this.banners.push(res);
          resolve(res);
        }
      }
    });
  };

  @action
  create = (data: BannerFormDataInterface) => {
    return LoadPromise(async (resolve, reject) => {
      const Banner = Parse.Object.extend("Banner");
      const banner = new Banner();
      this._mapToParse(banner, data);
      const parseFile = await this.createParseFile(data.file);
      banner.set("file", parseFile);
      const savedObj = await banner.save();
      this.banners.unshift(savedObj);
      resolve(savedObj);
    });
  };

  @action
  duplicate = (id: string) => {
    return LoadPromise(async (resolve) => {
      const query = new Parse.Query("Banner");
      const res = await query.get(id);

      const Banner = Parse.Object.extend("Banner");
      const banner = new Banner();

      let objectJSON = res.toJSON() as any;
      objectJSON.title = "(KOPI) " + objectJSON.title;
      delete objectJSON.objectId;
      delete objectJSON.created_by;
      objectJSON.published = false;
      banner.set(objectJSON);
      banner.set("file", res.get("file"));
      const savedObj = await banner.save();
      this.banners.unshift(savedObj);
      resolve(savedObj);
    });
  };

  async createParseFile(file: File): Promise<Parse.File> {
    var promise = new Promise<Parse.File>((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        if (reader.result) {
          const extension = file.name.split('.').pop();
          var parseFile = new Parse.File("banner_"+(new Date().getTime())+"."+extension, file);
          parseFile.save().then((result) => {
            resolve(result);
          });
        } else {
          reject();
        }
      };
    });
    return promise;
  }

  _mapToParse = (banner: Parse.Object, data: BannerFormDataInterface) => {
    banner.set("title", data.title);
    banner.set("url", data.url);
    banner.set("cta_text", data.ctaText);
    banner.set("sort_order", parseInt(data.sortOrder.toString()));
    banner.set("receiver_type", data.receiverType);
    banner.set("stores", data.stores);
    banner.set("segments", data.segments);
    banner.set("phone_numbers", (data.phoneNumbers ?? "").split(";"));
    banner.set("visible_from", data.visibleFrom);
    banner.set("visible_to", data.visibleTo);
    banner.set("published", data.published);
    banner.set("no_member_card_only", data.noMemberCardOnly);

    if (data.receiverType !== "segment-store") {
      banner.set("segments", null);
      banner.set("stores", null);
    }

    if (data.receiverType !== "specific-customer") {
      banner.set("phone_numbers", null);
    }

    return banner;
  };

  @action
  update = (data: BannerFormDataInterface) => {
    return LoadPromise(async (resolve, reject) => {
      const query = new Parse.Query("Banner");
      const banner = await query.get(data.id);

      this._mapToParse(banner, data);

      if (!data.existingFileUrl) {
        const parseFile = await this.createParseFile(data.file);
        banner.set("file", parseFile);
      }

      const savedObj = await banner.save();
      this.banners = replaceObjectById(this.banners, savedObj);
      resolve(savedObj);
    });
  };

  @action
  delete = (id: string) => {
    return LoadPromise(async (resolve, reject) => {
      const query = new Parse.Query("Banner");
      const res = await query.get(id);
      if (res) {
        await res.destroy();
        this.banners = removeObjectById(this.banners, res);
        resolve();
      } else reject();
    });
  };
}

export const bannerStore = new BannerStore();
