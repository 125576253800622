import Form from "components/Forms/Form";
import Input from "components/Forms/Input";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { FieldState, FormState } from "formstate";
import { requiredValidator } from "helpers/FormValidation";
import htmlToDraft from "html-to-draftjs";
import { observer } from "mobx-react";
import * as React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Button } from "rsuite";
import {
  ConsentDocumentFormDataInterface,
  ConsentDocumentType
} from "stores/ConsentDocumentStore";
import { loadingStore } from "stores/LoadingStore";
import { navigationStore } from "stores/NavigationStore";

interface State {
  editorState: EditorState;
}
interface Props {
  onSubmit: (data: ConsentDocumentFormDataInterface) => void;
  onSavePublish?: (data: ConsentDocumentFormDataInterface) => void;
  onDuplicate?: (id: string) => void;
  type: ConsentDocumentType;
  editData?: ConsentDocumentFormDataInterface;
  hideSaveButton?: boolean;
  onDelete?: () => void;
}

@observer
class ConsentDocumentForm extends React.Component<Props, State> {
  form: FormState<any> = new FormState({
    name: new FieldState("")
      .validators(requiredValidator)
      .disableAutoValidation(),
    internalId: new FieldState("").disableAutoValidation(),
    body: new FieldState("").validators(requiredValidator),
  });

  constructor(props: any) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
    };
  }

  componentDidMount() {
    if (this.props.editData) {
      const data = this.props.editData;
      this.form.$.name.reset(data.name);
      this.form.$.internalId.reset(data.internalId);
      this.form.$.body.reset(data.body);
      const contentBlock = htmlToDraft(data.body);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        this.setState({ editorState: editorState });
      }
    }
  }

  onSubmit = () => {
    const { onSubmit, editData } = this.props;
    const form = this.form.$;
    const data = {
      id: editData?.id ?? null,
      name: form.name.$,
      type: this.props.type,
      internalId: form.internalId.$,
      body: form.body.$,
    } as ConsentDocumentFormDataInterface;
    onSubmit(data);
  };

  onSavePublish = () => {
    const { onSavePublish, editData } = this.props;
    const form = this.form.$;
    const data = {
      id: editData?.id ?? null,
      name: form.name.$,
      type: this.props.type,
      internalId: form.internalId.$,
      body: form.body.$,
    } as ConsentDocumentFormDataInterface;
    if (onSavePublish) onSavePublish(data as ConsentDocumentFormDataInterface);
  };

  isLocked() {
    return (
      this.props.editData?.publishedAt !== null &&
      this.props.editData?.publishedAt !== undefined
    );
  }

  onEditorStateChange(editorState: EditorState) {
    this.setState({ editorState });
    this.form.$.body.reset(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  }

  openDocument(id: string) {
    var linkElem = document.createElement("a");
    linkElem.setAttribute(
      "href",
      `http://${window.location.host}/#/public/consent-documents/${id}/`
    );
    linkElem.setAttribute("target", "_blank");
    document.body.appendChild(linkElem);
    linkElem.click();
    document.body.removeChild(linkElem);
  }

  render() {
    const { hideSaveButton, editData, onDelete, onSavePublish, onDuplicate } =
      this.props;
    const form = this.form.$;
    const isLoading = loadingStore.isLoading;

    if (!this.state?.editorState) return;
    const { editorState } = this.state;
    return (
      <>
        <Form onValidSubmit={this.onSubmit} formState={this.form}>
          <Input
            disabled={this.isLocked()}
            label="Navn *"
            fieldState={form.name}
          />
          <Input
            disabled={this.isLocked()}
            label="Internt ID *"
            fieldState={form.internalId}
          />
          <br />
          {!this.isLocked() && (
            <div style={{ backgroundColor: "#FFF", padding: "1rem", border: "1px solid #d9d9d9" }}>
              <Editor
                editorState={editorState}
                onEditorStateChange={(state) => this.onEditorStateChange(state)}
              />
            </div>
          )}

          {this.isLocked() && (
            <div
              style={{ backgroundColor: "#FFF", padding: "1rem" }}
              dangerouslySetInnerHTML={{ __html: this.form.$.body.$ }}
            ></div>
          )}

          <div style={{ textAlign: "center", margin: "80px 0" }}>
            <Button
              style={!hideSaveButton ? { marginRight: "20px" } : undefined}
              onClick={() =>
                navigationStore.go("/consent-documents/" + this.props.type)
              }
            >
              Tilbage
            </Button>

            {!this.isLocked() && (
              <Button color="red" type="submit" loading={isLoading}>
                Gem kladde
              </Button>
            )}

            {onSavePublish && !this.isLocked() && (
              <Button
                style={{ marginLeft: "20px" }}
                appearance="primary"
                color="green"
                loading={isLoading}
                onClick={this.onSavePublish}
              >
                Gem og udgiv
              </Button>
            )}

            {onDuplicate && (
              <Button
                style={{ marginLeft: "20px" }}
                appearance="ghost"
                loading={isLoading}
                onClick={() => onDuplicate(editData?.id)}
              >
                Duplikér
              </Button>
            )}

            {this.props.editData && (
              <Button
                style={{ marginLeft: "20px" }}
                onClick={() => this.openDocument(this.props.editData?.id)}
              >
                Vis dokument
              </Button>
            )}
          </div>

          {onDelete && !this.isLocked() && (
            <div style={{ textAlign: "center", marginBottom: "60px" }}>
              <Button
                appearance="link"
                style={{ color: "grey" }}
                loading={isLoading}
                onClick={onDelete}
              >
                Slet dokument
              </Button>
            </div>
          )}
        </Form>
      </>
    );
  }
}

export default ConsentDocumentForm;
