import * as React from "react";
import { observer } from "mobx-react";
import { FieldState } from "formstate";
import { segmentStore } from "stores/SegmentStore";

interface Props {
  fieldState: FieldState<any>;
  checkedItems: string[];
  checkedState?: any;
  disabled?: boolean;
}

interface State {
  checkedState: any;
  options: any[];
}

@observer
class SelectSegment extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      checkedState: {},
      options: [],
    };

    segmentStore.getSegments().then(() => {
      const data = segmentStore.segments;
      const options = data.map((d) => ({
        value: d.id,
        label: d.get("name"),
        checked: props.checkedItems?.filter((di) => di === d.id).length > 0,
      }));

      this.setState(() => {
        let checkedState = Object.assign(
          {},
          ...options.map((x) => ({ [x.value]: x.checked }))
        );

        return {
          checkedState: checkedState,
          options: options,
        };
      });
    });
  }

  handleOnChange = (id: string) => {
    let { fieldState } = this.props;
    let checkedState = this.state.checkedState;

    checkedState[id] = !checkedState[id];

    this.setState(() => {
      return { checkedState: checkedState };
    });

    fieldState.onChange(
      Object.keys(checkedState)
        .map((k) => (checkedState[k] ? k : null))
        .filter((k) => k !== null)
    );
  };

  handleCheckAll() {
    let { fieldState } = this.props;
    let checkedState = this.state.checkedState;
    let checkAll =
      Object.keys(checkedState).filter((c: any) => checkedState[c]).length ===
      0;

    Object.keys(checkedState).forEach((e, i) => {
      checkedState[e] = checkAll;
    });

    this.setState(() => {
      return { checkedState: checkedState };
    });

    fieldState.onChange(
      Object.keys(checkedState)
        .map((k) => (checkedState[k] ? k : null))
        .filter((k) => k !== null)
    );
  }

  render() {
    const { options, checkedState } = this.state;
    const { disabled } = this.props;
    return (
      <div>
        <div>
          <button type="button" onClick={() => this.handleCheckAll()}>
            Vælg/fravælg alle
          </button>
        </div>
        {options.map((e, i) => (
          <div className="checkbox-wrapper" key={i}>
            <input
              id={e.value}
              type="checkbox"
              name="store"
              disabled={disabled}
              checked={checkedState[e.value]}
              value={e.value}
              onChange={() => this.handleOnChange(e.value)}
            />
            <label htmlFor={e.value}>{e.label}</label>
          </div>
        ))}
      </div>
    );
  }
}

export default SelectSegment;
